@import "../../../styles/colors.scss";
@import "../../../styles/constants.scss";

@keyframes appear-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.presentation {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .contentControls {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 180px;

    .logoHolder {
      width: 144px;

      .companyLogo {
        cursor: pointer;
        width: 100%;
        filter: grayscale(100%);
        transition: filter .2s;
      }
      .active, .companyLogo:hover {
        filter: grayscale(0%);
      }
    }

    @media screen and (max-width: $small-screen) {
      column-gap: 150px;

      .logoHolder {
        width: 120px;
      }
    }
    @media screen and (max-width: 900px) {
      margin-bottom: 100px;

      min-width: unset;
      width: 100%;
      max-width: 800px;
      
      padding-left: 10%;
      padding-right: 10%;
    }
    @media screen and (max-width: $mobile-screen) {
      margin-bottom: 60px;

      padding-left: 5%;
      padding-right: 5%;
      column-gap: 60px;
      
      .logoHolder {
        width: 100px;
      }
    }
  }

  .content {
    margin-top: 76px;
    width: 100%;
    max-width: 1221px;
    min-height: 416px;

    transform: translateX(14%);

    display: flex;
    align-items: center;
    column-gap: 180px;

    * {
      flex-shrink: 0;
      flex-grow: 0;
    }

    @media screen and (max-width: 1650px) {
      transform: translateX(0); 
    }
    @media screen and (max-width: $small-screen) {
      margin-top: 60px;
      max-width: 921px;
      min-height: 324px;
      column-gap: 140px;
      transform: translateX(13%);
    }
    @media screen and (max-width: 1250px) {
      transform: translateX(0);
    }
    @media screen and (max-width: 950px) {
      column-gap: 90px;
      max-width: 870px;
    }
    @media screen and (max-width: 900px) {
      margin-top: 0;
      
      width: unset;
      max-width: unset;
      
      flex-flow: column nowrap;
      align-items: center;
    }

    .mobileTitle {
      font-size: 36px;
      font-weight: 400;

      @media screen and (max-width: $mobile-screen) {
        font-size: 28px;
      }
    }

    .image {
      width: 344px;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      animation: appear-animation .5s ease-out;

      @media screen and (max-width: $small-screen) {
        width: 275px;
      }
      @media screen and (max-width: 900px) {
        margin-top: 70px;
        display: block;
        margin-left: auto;
        margin-right: auto;

        animation: none;

        width: unset;
        max-width: unset;
        height: 175px;
      }
      @media screen and (max-width: $mobile-screen) {
        margin-top: 54px;
        height: 125px;
      }
    }

    .contentText {
      @media screen and (max-width: 900px) {
        min-height: 325px;
        display: block;
      }

      @media screen and (max-width: $mobile-screen) {
        min-height: 325px;
      }

      .slide {
        width: 100%;
        max-width: 697px;

        animation: appear-animation .5s ease-out;

        .sHeader {
          font-weight: 500;
        }

        .sText {
          margin-top: 38px;
        }

        .findBtn {
          margin-top: 42px;
        }

        @media screen and (max-width: $small-screen) {
          width: 505px;

          .sText {
            margin-top: 32px;
          }

          .findBtn {
            margin-top: 34px;
          }
        }

        @media screen and (max-width: 900px) {
          width: 100%;
          max-width: unset;
          padding-left: 10%;
          padding-right: 10%;

          .findBtn {
            float: right;
          }

          .sHeader {
            text-align: center;
            transform: translateY(5px);
          }

          .sText {
            margin-top: 70px;
          }
        }

        @media screen and (max-width: $mobile-screen) {
          padding-left: 5%;
          padding-right: 5%;

          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          .sHeader, .sText {
            width: 100%;
          }

          .sHeader {
            transform: translateY(4px);
          }

          .sText {
            margin-top: 54px;
          }
          
          .findBtn {
            margin-top: 40px;
            float: unset;
          }
        }

      } 
    }

  }
}
